import Vue from 'vue'

const state = {
  calls: [],
  phoneNumber: '',
  incomingPopup: false,
  callKeypad: false,
  callReceived: false,
  disableHoldButton: true,
  disableMuteButton: true,
  currentSIPSession: null,

  mute: false,
  isCallOnHold: false,
  notOpenCall: true,

  callIsActive: false,

  callPick: false,

  openCall: false,

  phone: '',
  phoneOnCall: '',

  callOption: true,
  isSpam: false
}

const getters = {
  isSpam: s => s.isSpam
}

const mutations = {
  changePhoneNumber (state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  addCall (state, payload) {
    state.calls.push(payload)
  },
  removeCall (state, id) {
    const index = state.calls.findIndex(item => item.id === id)
    if (index !== -1) {
      state.calls.splice(index, 1)
    }
  },
  muteCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls[index], 'mute', payload.mute)
    }
  },
  holdCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls[index], 'hold', payload.hold)
    }
  },
  updateCall (state, payload) {
    const index = state.calls.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      Vue.set(state.calls, index, {
        ...state.calls[index],
        ...payload
      })
    }
  },
  setIncomingPopup (state, payload) {
    state.incomingPopup = payload
  },
  setCallKeypad (state, payload) {
    state.callKeypad = payload
  },
  setCallReceived (state, payload) {
    state.callReceived = payload
  },
  setDisableHoldButton (state, payload) {
    state.disableHoldButton = payload
  },
  setDisableMuteButton (state, payload) {
    state.disableMuteButton = payload
  },
  setCurrentSIPSession (state, payload) {
    state.currentSIPSession = payload
  },
  setMute (state, payload) {
    state.mute = payload
  },
  setIsCallOnHold (state, payload) {
    state.isCallOnHold = payload
  },
  setNotOpenCall (state, payload) {
    state.notOpenCall = payload
  },
  setCallIsActive (state, payload) {
    state.callIsActive = payload
  },
  setCallPick (state, payload) {
    state.callPick = payload
  },
  setOpenCall (state, payload) {
    state.openCall = payload
  },
  setPhone (state, payload) {
    state.phone = payload
  },
  setPhoneOnCall (state, payload) {
    state.phoneOnCall = payload
  },
  setCallOption (state, payload) {
    state.callOption = payload
  },
  setIsSpam (state, payload) {
    state.isSpam = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
