export default [
  {
    path: '/admin',
    name: 'AdminUsers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Users.vue')
  },
  {
    path: '/admin-payment',
    name: 'AdminPayment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Payment.vue')
  },
  {
    path: '/admin-invoice',
    name: 'AdminInvoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Invoice.vue')
  },
  {
    path: '/admin-number',
    name: 'AdminNumber',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Number.vue')
  },
  {
    path: '/admin-call-history',
    name: 'AdminCallHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/CallHistory.vue')
  },
  {
    path: '/admin-sms-history',
    name: 'AdminSmsHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/SmsHistory.vue')
  },
  {
    path: '/admin-logon-history',
    name: 'AdminLogonHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/LogonHistory.vue')
  },
  {
    path: '/admin-report-call',
    name: 'AdminReportCall',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ReportCall.vue')
  },
  {
    path: '/admin-report-sms',
    name: 'AdminReportSms',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/ReportSms.vue')
  }
]
