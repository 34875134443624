const host = window.location.host

const formatHost = (host) => {
  const parts = host.split('.')

  if (parts.includes('test')) {
    parts[parts.indexOf('test')] = 'testapi'
  } else if (parts.includes('portal')) {
    parts[parts.indexOf('portal')] = 'api'
  }

  const newHostname = parts.join('.')

  return `https://${host.replace(host, newHostname)}`
}

export const correctApiBaseUrl = () => {
  // const host = window.location.host

  // if (host.endsWith('freefon.app') || host.includes('localhost:')) {
  //   return process.env.VUE_APP_BASE_URL
  // }

  // return formatHost(host)

  // we don't need the write label api for reseller for now

  return process.env.VUE_APP_BASE_URL
}

export const isWhitelabel = (!host.endsWith('.freefone.app') && !host.includes('localhost:'))
